import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Structure as _Structure, addStructure, Read, delStructure, exitStructure, StructureGetpeople, StructureSetdirector, getAllowDeleteStructureList } from '@/api/updateUserInfo/structure';
import Head from '@/components/head/index';
import mailListSor from '@/components/StaffSelect/mailListRadiosor';
import StaffSelect from '@/components/StaffSelect/index';
export default {
  name: 'Structure',
  components: {
    StaffSelect: StaffSelect,
    Head: Head,
    mailListSor: mailListSor
  },
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '公司组织架构',
      tableData: [],
      staffResult: [],
      props: {
        multiple: true
      },
      staffResultSor: [],
      radioSor: false,
      loading: true,
      departmentArr: [],
      fullscreenLoading: false,
      department: '',
      mailListVisibleSor: false,
      userIde: '',
      staffSelectVisible: false,
      dynamicTags: [],
      condition: [],
      pvData: [],
      text: [],
      departmentName: '',
      independent: false,
      participate: false,
      participateData: [{
        id: '0',
        label: '不参与'
      }, {
        id: '1',
        label: '参与'
      }],
      recoveryData: [{
        id: '0',
        label: '不参与'
      }, {
        id: '1',
        label: '参与'
      }],
      Undertake: [{
        id: 1,
        label: '可认证'
      }, {
        id: 2,
        label: '不可认证'
      }, {
        id: 3,
        label: '社科高级'
      }],
      dialogPvVisible: false,
      options: [],
      PersonnelList: '',
      value1: [],
      value2: '',
      value: '',
      codeas: '',
      sjsgh: '',
      dialogDepartment: false,
      dialogFormVisible: false,
      rules: {
        name: [{
          required: true,
          message: '请输入必填项',
          trigger: 'change'
        }],
        pid: [{
          required: true,
          message: '请输入必填项',
          trigger: 'blur'
        }]
      },
      vals: '',
      temp: {
        id: '',
        independentEs: [],
        name: '',
        //部门名称
        director: '',
        //部门主管
        capacity: '1',
        //部门类型
        DyStagesName: '3',
        //是否支持分期付款
        participateIn: '0',
        UndertakeVal: 1,
        dataRecovery: '0'
      },
      sheren: '',
      labelse: [{
        value: '1',
        label: '中台'
      }, {
        value: '2',
        label: '前台'
      }, {
        value: '3',
        label: '后台'
      }, {
        value: '4',
        label: '渠道'
      }],
      DyStages: [{
        value: '3',
        label: '不涉及收费'
      }, {
        value: '2',
        label: '不支持'
      }, {
        value: '1',
        label: '支持'
      }]
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.Structure();
    } else {}
  },
  methods: {
    Structure: function Structure() {
      var _this = this;
      this.loading = true;
      _Structure({
        name: this.department
      }).then(function (respomse) {
        _this.tableData = respomse.data;
        _this.options = respomse.data;
        _this.loading = false;
      });
    },
    supervisor: function supervisor(row) {
      var _this2 = this;
      this.text = [];
      this.radioSor = true;
      this.mailListVisibleSor = true;
      this.userIde = String(row.id);
      row.director.forEach(function (element) {
        _this2.text.push(Number(element.id));
      });
    },
    SharedSustomersSor: function SharedSustomersSor(resultNameArr, result) {
      var _this3 = this;
      var data = {
        user_ids: String(result),
        structure_id: String(this.userIde)
      };
      StructureSetdirector(data).then(function (res) {
        _this3.$notify({
          title: '成功',
          message: '主管设置成功',
          type: 'success',
          duration: 2000
        });
        _this3.Structure();
      });
    },
    resetTemps: function resetTemps() {
      //清空公告弹窗
      this.temp.name = ''; //部门名称
      this.temp.capacity = '1'; //部门类型
    },
    capacityse: function capacityse(val) {
      this.temp.capacity = val;
    },
    capacityseE: function capacityseE(val) {
      this.temp.DyStagesName = val;
    },
    showStaff: function showStaff(id) {
      var _this4 = this;
      //人员列表
      StructureGetpeople({
        id: id
      }).then(function (res) {
        _this4.dialogPvVisible = true;
        _this4.pvData = res.data;
      });
    },
    handleClose: function handleClose(tag) {
      var CheckedNodes = this.$refs.independentEs.getCheckedNodes();
      CheckedNodes = CheckedNodes.filter(function (option) {
        return !(option.parent && option.parent.checked);
      });
    },
    handleEdit: function handleEdit(val, key) {
      var _this5 = this;
      //  新增/新增子栏目/编辑弹窗
      if (val == 'edit') {
        this.vals = '编辑部门';
        this.dialogFormVisible = true;
        if (key.children == undefined) {
          this.independent = true;
          this.participate = false;
        } else {
          this.participate = true;
          this.independent = false;
        }
        Read({
          id: String(key.id)
        }).then(function (respomse) {
          var data = respomse.data;
          _this5.temp.id = key.id;
          _this5.temp.DyStagesName = String(data.charge_type);
          _this5.temp.name = data.name;
          _this5.temp.participateIn = String(data.is_popularize);
          _this5.temp.dataRecovery = String(data.is_recovery);
          _this5.temp.UndertakeVal = Number(data.project_type);
          _this5.temp.capacity = String(data.capacity);
          _this5.temp.independentEs = data.share.split(',').map(function (e) {
            var _this5$getDepartmentN = _this5.getDepartmentNameById(e, _this5.options),
              id = _this5$getDepartmentN.id;
            return id;
          });
        });
      } else if (val == 'newly2') {
        this.vals = '新建子部门';
        this.dialogFormVisible = true;
        this.independent = false;
        this.temp.id = key.id;
      } else if (val == 'newly1') {
        this.vals = '新建部门';
        this.dialogFormVisible = true;
        this.independent = false;
      }
    },
    createData: function createData() {
      var _this6 = this;
      var data = {
        id: String(this.temp.id),
        name: this.temp.name,
        capacity: this.temp.capacity,
        charge_type: this.temp.DyStagesName
      };
      if (this.vals == '新建部门') {
        addStructure(data).then(function (respomse) {
          _this6.Structure();
          _this6.resetTemps();
          _this6.$notify({
            title: '成功',
            message: '新建部门成功',
            type: 'success',
            duration: 2000
          });
          _this6.dialogFormVisible = false;
        });
      } else if (this.vals == '新建子部门') {
        addStructure(data).then(function (respomse) {
          _this6.Structure();
          _this6.resetTemps();
          _this6.$notify({
            title: '成功',
            message: '新建部门成功',
            type: 'success',
            duration: 2000
          });
          _this6.dialogFormVisible = false;
        });
      } else if (this.vals == '编辑部门') {
        this.fullscreenLoading = true;
        var dataEs = {
          id: String(this.temp.id),
          name: this.temp.name,
          capacity: this.temp.capacity,
          charge_type: this.temp.DyStagesName,
          is_popularize: this.temp.participateIn,
          is_recovery: this.temp.dataRecovery,
          project_type: this.temp.UndertakeVal.toString()
        };
        var share = this.temp.independentEs.map(function (e) {
          return e[e.length - 1];
        }).toString(); // 部门的最后一位ID
        dataEs.share = share == ',' ? '' : share;
        exitStructure(dataEs).then(function (respomse) {
          _this6.Structure();
          _this6.resetTemps();
          _this6.$notify({
            title: '成功',
            message: '编辑部门成功',
            type: 'success',
            duration: 2000
          });
          _this6.fullscreenLoading = false;
          _this6.dialogFormVisible = false;
          _this6.temp.id = '';
        }).catch(function () {
          _this6.fullscreenLoading = false;
        });
        ;
      }
    },
    cancel: function cancel() {
      this.dialogFormVisible = false;
      this.temp.id = '';
      this.codeas = '';
      this.departmentName = '';
      this.participate = false;
    },
    staffSelectConfirm: function staffSelectConfirm(resultNameArr, result) {
      this.sheren = result;
      this.temp.director = String(resultNameArr);
    },
    deleteDepartment: function deleteDepartment(row) {
      var _this7 = this;
      // if(this.temp.id != ''){
      this.sjsgh = row;
      this.$confirm('此操作将永久删除该部门, 是否继续?', '删除部门', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = {};
        console.log(_this7.codeas);
        if (_this7.codeas == '') {
          data.id = String(row.id);
        } else {
          data.id = String(row.id);
          data.transfer_structure_id = String(_this7.departmentName);
        }
        delStructure(data).then(function (respomse) {
          console.log(respomse);
          if (respomse == 100007) {
            _this7.codeas = respomse.code;
            _this7.dialogDepartment = true;
            getAllowDeleteStructureList({
              structure_id: row.id
            }).then(function (res) {
              _this7.departmentArr = res.data;
            });
          } else {
            _this7.Structure();
            _this7.dialogDepartment = false;
            _this7.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            });
            _this7.sjsgh = '';
            _this7.codeas = '';
          }
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
      // }else{
      //   this.$message({
      //     type: 'info',
      //     message: '请选择公告'
      //   });
      // }
    },
    departmentClick: function departmentClick() {
      this.deleteDepartment(this.sjsgh);
    },
    ClassSearchInput: function ClassSearchInput(val) {
      this.Structure();
    }
  }
};