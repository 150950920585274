import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.function.name";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getUserList } from '@/api/updateUserInfo/structure';
import { mapState } from 'vuex';
import SelectAllBlock from "./SelectAllBlock";
export default {
  name: 'mailListSor',
  props: {
    value: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    disableArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    text: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    item: {
      type: String,
      required: true,
      default: false
    },
    radio: {
      type: Boolean,
      default: false
    },
    condition: {
      type: Array
    },
    showRequire: {
      type: Boolean,
      default: true
    }
  },
  components: {
    SelectAllBlock: SelectAllBlock
  },
  data: function data() {
    return {
      staff_list: 1,
      staffList1: undefined,
      staffList2: undefined,
      staffList3: undefined,
      search: undefined,
      nameArr: undefined,
      type: 1,
      arr: [],
      checkList: [],
      checkListsw: '',
      multiCheckListArr: [],
      nameObj: {},
      conData: [],
      table: 'left',
      result: undefined,
      clickConfirm: false,
      empty: false,
      siDe: '',
      userId: this.$store.getters.id
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    name: function name(state) {
      return state.user.name;
    }
  })), {}, {
    dialogTableVisible: {
      get: function get() {
        return this.visible;
      },
      set: function set(newV) {
        this.$emit('update:visible', newV);
        if (!this.clickConfirm) {
          // 点取消或其他地方取消模态框
          this.$emit('input', []);
          this.clearData();
        } else {
          this.clickConfirm = false;
        }
      }
    },
    multiCheckList: function multiCheckList() {
      return this.multiCheckListArr.flat();
    },
    checkNum: function checkNum() {
      if (this.radio) {
        return this.checkList.length;
      } else {
        return this.table === 'left' ? this.checkList.length : this.multiCheckList.length;
      }
    },
    disable: function disable() {
      return Boolean(this.checkNum);
    }
  }),
  watch: {
    table: function table(newV) {
      this.checkList = [];
      this.multiCheckListArr = [];
      this.search = undefined;
      this.type = ['left', 'middle', 'right'].findIndex(function (x) {
        return x === newV;
      });
    },
    type: {
      handler: 'getList',
      immediate: false
    },
    disableArr: {
      handler: 'getList'
    },
    item: function item(_item) {
      this.getList('', _item);
      this.siDe = _item;
    },
    text: function text(val) {
      this.checkList = val;
      return this.checkList;
    },
    showRequire: function showRequire(newV) {},
    search: function search(oldVal) {
      this.getList(oldVal, this.siDe);
    }
  },
  methods: {
    getList: function getList(search, id) {
      var _this = this;
      var param;
      if (this.search == undefined) {
        // param = { type: String(this.type), structure_id: String(id) };
        param = {
          type: String(this.type)
        };
      } else {
        param = {
          type: String(this.type),
          real_name: search
        };
      }
      getUserList(param).then(function (res) {
        if (res.data != '') {
          _this.empty = false;
          if (_this.type == 0) {
            res.data.map(function (e) {
              _this.nameObj[e.id] = e.realname;
            });
            _this.arr = res.data;
          } else if (_this.type == 1) {
            res.data.map(function (e) {
              e.user_arr.map(function (item) {
                _this.nameObj[item.id] = item.realname;
              });
            });
            _this.arr = res.data;
          } else if (_this.type == 2) {
            res.data.map(function (e) {
              e.user_arr.map(function (item) {
                _this.nameObj[item.id] = item.realname;
              });
            });
            _this.arr = res.data;
          }
        } else {
          _this.empty = true;
        }
      });
    },
    clearData: function clearData() {
      this.table = 'left';
      this.checkList = [];
      this.multiCheckListArr = [];
    },
    setData: function setData(arr) {
      this.table = 'left';
      this.checkList = arr;
      this.multiCheckListArr = arr;
    },
    filterData: function filterData(data) {
      var _this2 = this;
      return data.map(function (e) {
        return {
          name: e.name,
          children: e.children.filter(function (ee) {
            return ee.realname.indexOf(_this2.search) !== -1;
          })
        };
      }).filter(function (x) {
        return x.children.length;
      });
    },
    assignOptions: function assignOptions(obj) {
      var index = obj.index,
        arr = obj.arr;
      this.$set(this.multiCheckListArr, index, arr);
    },
    scrollTo: function scrollTo(index) {
      var scrollDiv = this.$el.querySelectorAll('.scrollDiv');
      this.$el.querySelector('.ova').scrollTop = scrollDiv[index].offsetTop - scrollDiv[0].offsetTop;
    },
    radioFix: function radioFix() {
      // if (this.radio) {
      //     if (this.checkList.length === 2) {
      //         return this.checkList.shift();
      //     }
      // }
    },
    confirm: function confirm() {
      var _this3 = this;
      this.clickConfirm = true;
      this.search = '';
      this.dialogTableVisible = false;
      var result = this.checkList.length ? this.checkList : this.multiCheckList;
      this.$emit('input', result);
      var resultNameArr = result.map(function (e) {
        return _this3.nameObj[e];
      });
      this.$emit('SharedSustomersSor', resultNameArr, result);
      this.clearData();
    },
    framework: function framework() {
      this.search = '';
      this.dialogTableVisible = false;
    }
  }
};