var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container framework" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "88px" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "40px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 13 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新建部门"],
                                expression: "['新建部门']",
                              },
                            ],
                            staticClass: "btnse",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit("newly1", "")
                              },
                            },
                          },
                          [_vm._v("\n                        新建部门")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { staticStyle: { float: "right" }, attrs: { span: 4 } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "input-with-select",
                            attrs: {
                              clearable: "",
                              placeholder: "部门名称搜索",
                              size: "small",
                            },
                            model: {
                              value: _vm.department,
                              callback: function ($$v) {
                                _vm.department = $$v
                              },
                              expression: "department",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.ClassSearchInput },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "tb",
                staticStyle: { width: "100%", "margin-bottom": "20px" },
                attrs: {
                  data: _vm.tableData,
                  "row-key": "id",
                  border: "",
                  "tree-props": {
                    children: "children",
                    hasChildren: "hasChildren",
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "部门名称",
                    "min-width": "160",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "director_name",
                    label: "部门主管",
                    width: "280",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "people_num",
                    label: "人数",
                    width: "160",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "查看人员列表",
                    width: "260",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "goon" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showStaff(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("人员列表")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3404953614
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", "min-width": "260", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["设置主管"],
                                    expression: "['设置主管']",
                                  },
                                ],
                                staticClass: "btnse",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.supervisor(scope.row)
                                  },
                                },
                              },
                              [_vm._v("设置主管")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["新建子部门"],
                                    expression: "['新建子部门']",
                                  },
                                ],
                                staticClass: "btnse",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit("newly2", scope.row)
                                  },
                                },
                              },
                              [_vm._v("新建子部门")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["编辑"],
                                    expression: "['编辑']",
                                  },
                                ],
                                staticClass: "btnse",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit("edit", scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["删除"],
                                    expression: "['删除']",
                                  },
                                ],
                                staticClass: "btnse",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteDepartment(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4158941065
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "big-dialog",
                staticStyle: { margin: "0 auto" },
                attrs: {
                  title: _vm.vals,
                  visible: _vm.dialogFormVisible,
                  width: "600px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                  close: _vm.resetTemps,
                },
              },
              [
                _c(
                  "el-form",
                  {
                    staticStyle: {
                      "min-width": "200px",
                      "max-width": "400px",
                      "margin-left": "50px",
                    },
                    attrs: {
                      rules: _vm.rules,
                      model: _vm.temp,
                      "label-position": "right",
                      "label-width": "130px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "部门名称" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入部门名称" },
                          model: {
                            value: _vm.temp.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "name", $$v)
                            },
                            expression: "temp.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.independent
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "独立公海" } },
                          [
                            _c("el-cascader", {
                              ref: "independentEs",
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "选择部门",
                                clearable: "",
                                options: _vm.options,
                                filterable: "",
                                props: _vm.props,
                              },
                              model: {
                                value: _vm.temp.independentEs,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "independentEs", $$v)
                                },
                                expression: "temp.independentEs",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "部门类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择" },
                            on: { change: _vm.capacityse },
                            model: {
                              value: _vm.temp.capacity,
                              callback: function ($$v) {
                                _vm.$set(_vm.temp, "capacity", $$v)
                              },
                              expression: "temp.capacity",
                            },
                          },
                          _vm._l(_vm.labelse, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.independent
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "是否支持分期付款" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.capacityseE },
                                model: {
                                  value: _vm.temp.DyStagesName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "DyStagesName", $$v)
                                  },
                                  expression: "temp.DyStagesName",
                                },
                              },
                              _vm._l(_vm.DyStages, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.participate
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "是否参与推广申请" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.temp.participateIn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "participateIn", $$v)
                                  },
                                  expression: "temp.participateIn",
                                },
                              },
                              _vm._l(_vm.participateData, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.label, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.participate
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "是否参与数据回收" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.temp.dataRecovery,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "dataRecovery", $$v)
                                  },
                                  expression: "temp.dataRecovery",
                                },
                              },
                              _vm._l(_vm.recoveryData, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.label, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.independent
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "部门承接项目" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.temp.UndertakeVal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "UndertakeVal", $$v)
                                  },
                                  expression: "temp.UndertakeVal",
                                },
                              },
                              _vm._l(_vm.Undertake, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.label, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: { "text-align": "right" },
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c("el-button", { on: { click: _vm.cancel } }, [
                      _vm._v("取消"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading.fullscreen.lock",
                            value: _vm.fullscreenLoading,
                            expression: "fullscreenLoading",
                            modifiers: { fullscreen: true, lock: true },
                          },
                        ],
                        attrs: { type: "primary" },
                        on: { click: _vm.createData },
                      },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: { visible: _vm.dialogPvVisible, title: "人员列表" },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogPvVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.pvData,
                      border: "",
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", label: "序号", width: "50" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "realname", label: "姓名" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "状态" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    Number(scope.row.positive_status) != 0
                                      ? "已转正"
                                      : "未转正"
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4121383406
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "是否有效" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status == 0
                                  ? _c("span", [_vm._v("禁用")])
                                  : scope.row.status == 1
                                  ? _c("span", [_vm._v("启用")])
                                  : _c("span", [_vm._v("未激活")]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2699948437
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.dialogPvVisible = false
                          },
                        },
                      },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("staff-select", {
              ref: "staffSelect",
              attrs: {
                radio: false,
                condition: _vm.condition,
                visible: _vm.staffSelectVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.staffSelectVisible = $event
                },
                staffSelectConfirm: _vm.staffSelectConfirm,
              },
              model: {
                value: _vm.staffResult,
                callback: function ($$v) {
                  _vm.staffResult = $$v
                },
                expression: "staffResult",
              },
            }),
            _vm._v(" "),
            _c("mailListSor", {
              ref: "staffSelect",
              attrs: {
                item: _vm.userIde,
                radio: _vm.radioSor,
                text: _vm.text,
                condition: _vm.condition,
                visible: _vm.mailListVisibleSor,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.mailListVisibleSor = $event
                },
                SharedSustomersSor: _vm.SharedSustomersSor,
              },
              model: {
                value: _vm.staffResultSor,
                callback: function ($$v) {
                  _vm.staffResultSor = $$v
                },
                expression: "staffResultSor",
              },
            }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: { visible: _vm.dialogDepartment, title: "删除部门" },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogDepartment = $event
                  },
                  close: _vm.cancel,
                },
              },
              [
                _c(
                  "el-form",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                    attrs: {
                      "label-position": "right",
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "", prop: "titles" } },
                      [
                        _c("span", [
                          _vm._v(
                            "该部门公海中还存在数据，请选择公海数据要转移的部门"
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "", prop: "titles" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择部门" },
                            model: {
                              value: _vm.departmentName,
                              callback: function ($$v) {
                                _vm.departmentName = $$v
                              },
                              expression: "departmentName",
                            },
                          },
                          _vm._l(_vm.departmentArr, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.departmentClick },
                      },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }